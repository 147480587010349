.logoImage {
    border-radius: 50%;
    overflow: hidden;
    background: white;
    min-width: var(--size, 27px);
    max-width: var(--size, 27px);
    min-height: var(--size, 27px);
    max-height: var(--size, 27px);

    .generatedIcon {
        display: flex;
    }
}
