.switch {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 12px;

    &:not(.left) {
        &.small {
            .ball {
                transform: translateX(10px);
            }
        }
        .ball {
            transform: translateX(14px);
            background-color: var(--c-primary);
        }
    }

    .switchElement {
        width: 28px;
        height: 14px;
        border: 1px solid var(--c-grey-80);
        border-radius: 40px;
        margin: 0 4px;
    }

    .ball {
        position: absolute;
        width: 8px;
        height: 8px;
        background-color: var(--c-mid);
        border-radius: 50%;
        top: 2px;
        left: 2px;
        transition: transform 0.4s cubic-bezier(1, 0, 0, 1);
    }
}
