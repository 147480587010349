.numericInput {
    display: flex;
    flex-direction: row;

    // Prevent from being overwritten by global styles
    .input {
        margin: 0;
        padding: 4px 8px;
        border-radius: 0;
        box-sizing: border-box;
        font-size: 12px;
        height: 26px;

        &:focus::placeholder {
            color: transparent;
        }
    }

    .withSymbol {
        background-color: var(--c-grey-40);
        color: var(--c-lightest);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 6px;
        font-weight: 700;
        min-width: 22px;
        width: 22px;
        max-width: 22px;
        font-size: 12px;
        height: 26px;
    }

    &.error {
        outline: 1px solid var(--c-red);
        box-shadow: 0 0 10px var(--c-red);
    }

    &.large {
        input {
            font-size: 20px;
            height: 60px;
            padding: 13px 10px;
            margin: 0;
        }

        .withSymbol {
            min-width: 36px;
            width: 36px;
            max-width: 36px;
            font-size: 20px;
            height: 60px;
        }
    }
}

.tooltip {
    width: 100%;
}
