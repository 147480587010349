.tableViewSelector {
    display: flex;
    flex-direction: row;
    column-gap: 28px;

    .viewWrap {
        display: flex;
        flex-direction: row;
        column-gap: 4px;
        align-items: center;
        cursor: pointer;

        .viewText {
            font-size: 12px;
            font-weight: bold;
            color: var(--c-contrast-3);
        }

        .icon {
            width: 16px;
            height: 16px;
            background-color: var(--c-contrast-3);
            top: -1px;
        }

        &:hover {
            .viewText {
                color: var(--c-contrast-4);
            }

            .icon {
                background-color: var(--c-contrast-4);
            }
        }

        &.selected {
            .viewText {
                color: var(--c-primary);
            }

            .icon {
                background-color: var(--c-primary);
            }
        }
    }
}
