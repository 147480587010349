form {
    display: inline-flex;
    flex-direction: column;
    width: 90%;
    max-width: 400px;
    padding-bottom: 20px;
}

input:not([type='submit']) {
    text-align: var(--align, center);
    font-family: var(--f-primary);
    border: none;
    background-color: var(--c-grey-8);
    color: var(--c-fg);
    border-radius: var(--b-radius);
    font-size: var(--font-size, 20px);
    font-weight: bold;
    padding: 16px 20px;
    margin: var(--margin, 20px) 0;
    width: 100%;
    line-height: 1;
    appearance: none;
    height: var(--height, auto);
    animation-duration: 200ms;
}

input.hasError {
    color: var(--c-red);
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition:
        background-color 600000s 0s,
        color 600000s 0s;
}

input[type='submit'] {
    font-family: var(--f-primary);
    border: none;
    background-color: var(--c-cta-base);
    color: var(--c-bg);
    border-radius: var(--b-radius);
    font-weight: bold;
    padding: 14px 0 12px;
    font-size: 12px;
    width: 120px;
    display: inline-block;
    margin: auto;
    line-height: 1;
    box-shadow: 0 0 8px rgba(black, 0.5);
    transition:
        box-shadow 0.2s ease-in-out,
        opacity 0.4s ease-in-out,
        transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    appearance: none;

    &:hover {
        box-shadow: 0 0 20px rgba(black, 0.45);
        // transform: scale(1.06);
    }

    &:disabled {
        cursor: not-allowed;
    }
}
