.searchBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;
    height: 40px;
    width: 100%;
    position: relative;

    &.large {
        height: 52px;
    }

    .input {
        height: 100%;
        text-align: left;
        font-weight: normal;
        font-size: 16px;
        padding-left: 40px;
        padding-right: 40px;
        text-overflow: ellipsis;

        &:focus::placeholder {
            color: transparent;
        }
    }

    .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 12px;
        z-index: 1;
    }

    .clearBtn {
        position: absolute;
        right: 12px;
        transform: scale(1.25);
    }
}
