.imageContainer {
    position: relative; // Container set to relative
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: flex;

    &.expandable {
        width: 71px; // Set to the width of the extended image
    }

    .extendedImage {
        position: absolute;
        top: 0px;
        left: -47px;
        width: 71px;
        height: 24px;

        transition:
            all 0.2s ease-in-out,
            opacity 0s;
        clip-path: inset(0 0 0 47px);
        opacity: 0;
        transition-delay: 0s, 0.2s;

        &.isExtended {
            transition: all 0.2s ease-in-out;
            opacity: 1;
            z-index: 1;
            clip-path: inset(0 0 0 0);
            translate: 23px;
            @media (max-width: 767px) {
                translate: 48px;
            }
        }
    }
}
