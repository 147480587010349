$border-radius: 8px;

.dropdown {
    border-radius: $border-radius;
    transition: background-color 0.2s linear;
    padding: 8px 12px;
    font-size: 14px;
    color: var(--c-contrast-4);

    .selected {
        font-weight: normal;
        cursor: pointer;
        text-overflow: ellipsis;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }

    .arrow {
        top: 8px;

        &.arrowUp {
            transform: translateY(-50%);
        }
    }

    .options {
        position: absolute;
        transition: background-color 0.2s linear;
        display: flex;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 5;
        border-radius: $border-radius;
        box-shadow: 0 0 10px rgba(black, 0.2);
        padding-bottom: 8px;

        scrollbar-width: 5px;

        ::-webkit-scrollbar {
            width: 5px;
        }

        ::-webkit-scrollbar-track {
            background-color: transparent;
        }

        ::-moz-scrollbar-track {
            background-color: transparent;
        }

        ul {
            display: flex;
            flex-direction: column;
            border-radius: 0 0 $border-radius $border-radius;
            transition: background-color 0.2s linear;
            width: 100%;
            max-height: 220px;
            overflow-y: auto;
        }

        .selectedOpen {
            color: var(--c-contrast-4);
            padding: 12px 16px;
            border-radius: $border-radius $border-radius 0 0;
        }

        li {
            font-weight: normal;
            font-size: 14px;
            color: var(--c-contrast-3);
            padding: 4px 12px;
            cursor: pointer;
            text-overflow: ellipsis;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin: 0 8px;
            border-radius: 8px;

            &:hover {
                background-color: var(--c-primary-10);
            }

            &.selectedOption {
                color: var(--c-primary);
            }
        }

        &.hidden {
            display: none;
        }
    }

    &.small {
        font-size: 12px;
        text-align: center;
        padding: 12px 16px;
        transition: background-color 0.2s linear;

        .selected {
            padding: 0;
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            line-height: 1;
            color: var(--c-contrast-4);

            &::selection {
                background-color: transparent;
            }
        }

        .name {
            &::selection {
                color: var(--c-contrast-4);
                background-color: transparent;
            }
        }

        .arrow {
            margin-left: 8px;
            min-width: 8px;
            max-width: 8px;
            margin-top: 0;

            &.arrowUp {
                transform: translateY(-50%);
                top: 6px;
            }
        }

        .selectedOpen {
            background-color: var(--c-grey-8);
            color: var(--c-grey-60);
            padding: 12px 16px;
            border-radius: $border-radius $border-radius 0 0;
        }

        .options {
            ul {
                padding: 0;
            }

            li {
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: center;
                align-items: center;
                gap: 8px;
                color: var(--c-contrast-4);

                &:first-child,
                &:last-child {
                    padding: 4px 10px;
                }

                &::selection {
                    color: var(--c-contrast-4);
                    background-color: transparent;
                }
            }
        }
    }
}
