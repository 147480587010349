.itemWithLogo {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 8px;

    .assetName {
        margin-left: 8px;
        white-space: nowrap;
        color: var(--c-contrast-6);
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;
    }

    .imgWithIconWrap {
        display: flex;
        justify-content: center;

        .verifiedWrap {
            position: absolute;
            right: -5px;
            bottom: -5px;
        }

        .statusWrap,
        .operatorWrap {
            position: absolute;
            right: -4px;
            bottom: -2px;

            .circle {
                width: 16px;
                height: 16px;
                border-radius: 50%;

                &.active {
                    background-color: var(--c-green);
                }

                &.inactive {
                    background-color: var(--c-orange);
                }
            }
        }

        .operatorWrap {
            right: -4px;
            bottom: -8px;
            .circle {
                width: 28px;
                height: 28px;
            }
        }
    }

    .detailsWrap {
        display: flex;
        flex-direction: column;

        &.reverse {
            flex-direction: column-reverse;
        }

        &.withDetails {
            gap: 4px;
        }

        .name {
            display: block;
            font-size: 14px;
            font-weight: 500;
            color: var(--c-contrast-2);
            word-wrap: break-word;
            white-space: normal;
            overflow: hidden;
            // 2 lines max
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            line-height: 1;
            letter-spacing: 0.5px;
            @media (max-width: 767px) {
                overflow-wrap: anywhere;
            }
        }

        .details {
            font-size: 13px;
            color: var(--c-contrast-3);

            &.active {
                color: var(--c-green);
            }

            &.inactive {
                color: var(--c-red);
            }

            &.asName {
                color: var(--c-contrast-4);
            }
        }
    }

    &.medium {
        gap: 8px;
        line-height: 1;

        .detailsWrap {
            .name {
                font-size: 16px;
            }

            .details {
                font-size: 14px;
            }
        }
    }

    &.large {
        gap: 12px;
        line-height: 1;

        .detailsWrap {
            .name {
                font-size: 20px;
                line-height: 22px;
            }

            .details {
                font-size: 18px;
            }
        }
    }
}

// Overwrite tooltip's styles
.tooltip {
    text-align: center !important;
    padding: 4px 8px !important;
    width: 72px !important;
    font-size: 10px !important;
    top: 20px !important;
    left: -4px !important;
}
