$arrow-size: 6px;
$hover-space: 6px;
$width: 86px;

.tooltip {
    position: relative;

    .icons {
        visibility: hidden;
        opacity: 0;
        margin-bottom: 0;
        transition: opacity 0.2s ease-in-out;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding: 6px 8px;
        background-color: var(--c-contrast-1);
        border: 1px solid var(--c-contrast-2);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        border-radius: 6px;
        width: $width;

        // Position below the target
        position: absolute;
        bottom: 6px;
        left: 50%;
        transform: translate(-50%, calc(100% + $hover-space));
        z-index: 999; // on top of everything including navbar

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: -12px;
            left: 50%;
            border-style: solid;
        }

        &::before {
            margin-left: -$arrow-size;
            border-width: $arrow-size;
            border-color: transparent transparent var(--c-contrast-2) transparent;
        }

        &::after {
            margin-left: -$arrow-size + 1px;
            border-width: $arrow-size - 1px;
            border-color: transparent transparent var(--c-contrast-1) transparent;
            margin-top: 2px;
        }

        .iconWrap {
            line-height: 1;
            width: 16px;
            height: 16px;
            background-color: var(--c-fg);
            border-radius: 50%;
            text-align: center;
            opacity: 0.75;
            transition: opacity 0.2s ease-in-out;
            cursor: pointer;

            &:hover {
                opacity: 1;
            }

            .icon {
                background-color: var(--c-bg);
            }

            .linkedIn {
                width: 10px;
                height: 10px;
            }

            .twitter {
                top: -1px;
                width: 8px;
                height: 8px;
            }

            .telegram {
                margin-left: -2px;
                width: 8px;
                height: 10px;
            }
        }
    }

    &.hover {
        .icons {
            visibility: visible;
            opacity: 1;
            transform: translate(-50%, calc(100% + $hover-space));
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 6px;
            left: 50%;
            transform: translate(-50%, 100%);
            width: $width;
            height: $hover-space;
        }
    }

    @media (min-width: 768px) and (any-hover: hover) {
        &:hover {
            .icons {
                visibility: visible;
                opacity: 1;
                transform: translate(-50%, calc(100% + $hover-space));
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 6px;
                left: 50%;
                transform: translate(-50%, 100%);
                width: $width;
                height: $hover-space;
            }
        }
    }
}
