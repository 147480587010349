.toggleContainer {
    position: relative;
    display: flex;
    margin: 20px 0 0 0;
    z-index: 1;
    border-radius: 26px;
    background-color: var(--c-contrast-1);
    width: 100%;
}

.toggleBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 10px 30px;
    font-size: 14px;
    font-weight: 400;
    border: none;
    cursor: pointer;
    background-color: transparent;
    border-radius: 26px;
    color: var(--c-contrast-3);
    transition: all 0.3s;
    line-height: 1;

    @media (max-width: 767px) {
        font-size: 12px;
        padding: 10px 10px;
    }

    &:focus {
        outline: none;
    }

    &.active {
        color: var(--c-contrast-4);
    }

    &:hover {
        color: var(--c-contrast-4);
    }

    .vppIcon {
        display: inline-flex;
        margin-right: 10px;
        width: 72px;
        height: 24px;

        img {
            object-fit: contain;
        }
    }
}

.slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.32);
    transition: all 0.3s;
    border-radius: 26px;
    background-color: rgba(239, 239, 239, 0.5);

    [data-theme='dark'] & {
        background-color: rgba(239, 239, 239, 0.1);
    }
}

.slideRight {
    translate: 100%;
}
