.video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    opacity: 0;

    &[data-loaded='true'] {
        animation: FadeIn 0.8s linear forwards;
    }

    @keyframes FadeIn {
        to {
            opacity: 1;
        }
    }
}
