$arrow-size: 10px;
$tooltip-width: 180px;
$hover-space: 8px;

.tooltip {
    position: relative;
    width: fit-content; // center the tooltip above the target

    // NB: inputs should be wrapped with width: 100%
    &.inputWrap {
        width: 100%;
    }

    .text {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.2s ease-in-out;
        font-size: 12px;
        color: var(--c-contrast-3);
        padding: 8px 14px;
        line-height: 1.5;
        text-align: center;
        width: 180px;
        background-color: var(--c-contrast-1);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        border-radius: 8px;

        // Position above the target
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, calc(-100% - $hover-space));
        z-index: 999; // on top of everything including navbar

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            border-style: solid;
        }

        &::before {
            margin-left: -$arrow-size;
            border-width: $arrow-size;
            border-color: var(--c-contrast-2) transparent transparent transparent;
        }

        &::after {
            margin-left: -$arrow-size + 1px;
            border-width: $arrow-size - 1px;
            border-color: white transparent transparent transparent;
            margin-top: -1px;
        }
    }

    &.hoverable {
        .text {
            visibility: hidden;
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
            margin-bottom: 0;
            transform: translate(-50%, -100%);
            text-align: left;
        }

        @media (min-width: 768px) and (any-hover: hover) {
            &:hover {
                .text {
                    visibility: visible;
                    opacity: 1;
                    transform: translate(-50%, calc(-100% - $hover-space));
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, -100%);
                    width: 180px;
                    height: $hover-space;
                }
            }
        }
    }

    .more {
        color: var(--c-primary);
        cursor: pointer;
    }
}

.tooltipContrast {
    position: relative;
    width: fit-content; // center the tooltip above the target

    // NB: inputs should be wrapped with width: 100%
    &.inputWrap {
        width: 100%;
    }

    .text {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.2s ease-in-out;
        font-size: 12px;
        color: var(--c-contrast-1);
        padding: 8px 14px;
        line-height: 1.25;
        text-align: center;
        width: 180px;
        background-color: var(--c-contrast-6);
        border: 1px solid var(--c-contrast-6);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        border-radius: 8px;

        // Position above the target
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, calc(-100% - $hover-space));
        z-index: 999; // on top of everything including navbar

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            border-style: solid;
        }

        &::before {
            margin-left: -$arrow-size;
            border-width: $arrow-size;
            border-color: var(--c-contrast-6) transparent transparent transparent;
        }

        &::after {
            margin-left: -$arrow-size + 1px;
            border-width: $arrow-size - 1px;
            border-color: var(--c-contrast-6) transparent transparent transparent;
            margin-top: -1px;
        }
    }

    &.hoverable {
        .text {
            visibility: hidden;
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
            margin-bottom: 0;
            transform: translate(-50%, -100%);
            text-align: left;
        }

        @media (min-width: 768px) and (any-hover: hover) {
            &:hover {
                .text {
                    visibility: visible;
                    opacity: 1;
                    transform: translate(-50%, calc(-100% - $hover-space));
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, -100%);
                    width: 180px;
                    height: $hover-space;
                }
            }
        }
    }

    .more {
        color: var(--c-primary);
        cursor: pointer;
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--c-overlay-bg);
    z-index: 999;
    text-align: left;

    .contentWrap {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: var(--c-contrast-1);
        border: 1px solid var(--c-contrast-2);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        min-width: 300px;
        width: 60%;
        max-width: 500px;
        padding: 20px 20px 40px 20px;

        .headerWrap {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
        }
    }

    .content {
        padding: 0 20px 0 10px;
        margin: 4px 8px;
        font-size: 14px;
        line-height: 1.2;
        color: var(--c-contrast-5);

        .title {
            margin-bottom: 20px;
            font-size: 16px;
            font-weight: bold;
            color: var(--c-contrast-5);
        }

        .contentText {
            p {
                margin-top: 10px;
            }

            h4 {
                margin-top: 20px;
            }
        }

        .gradient {
            position: fixed;
            bottom: 0;
            width: calc(100% - 90px);
            height: 20%;
            max-height: 100px;
            background-repeat: no-repeat;
        }
    }

    ::-webkit-scrollbar-track {
        margin: 20px;
    }

    ::-moz-scrollbar-track {
        margin: 20px;
    }
}

.closeBtn {
    width: 20px;
    height: 20px;
    background: transparent;
    border: none;
    padding: 0;
    margin-top: -2px;

    .bar1,
    .bar2 {
        position: absolute;
        width: 12px;
        height: 3px;
        background-color: var(--c-red);
        top: 50%;
        left: 50%;
        border-radius: 8px;
    }

    .bar1 {
        transform: translate(-50%, -50%) rotate(-45deg);
    }

    .bar2 {
        transform: translate(-50%, -50%) rotate(45deg);
    }
}
