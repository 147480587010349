.icon {
    width: var(--size, 16px);
    height: var(--size, 16px);
    display: inline-block;
    mask-size: contain;
    mask-position: center center;
    mask-repeat: no-repeat;
    background-color: var(--color, var(--c-fg));
    mask-image: var(--svg);
    transition: background-color 0.2s ease;
}

.icon-og {
    mask: none;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: var(--svg);
    background-color: transparent;
}

.icon-brand {
    --svg: url('/static/svg/icon-new.svg');
}

.icon-sreth {
    --svg: url('/static/svg/sreth-icon.svg');
}

.icon-brand-old {
    --svg: url('/static/svg/icon.svg');
}

.icon-heart {
    --size: 20px;
    --svg: url('/static/svg/heart.svg');
}

.icon-summit {
    --svg: url('/static/svg/summit.svg');
}

.icon-vsp {
    --svg: url('/static/svg/vsp-tag.svg');
}

.icon-vsp-extended {
    --svg: url('/static/svg/vsp-tag-extended.svg');
}

.icon-api {
    --svg: url('/static/svg/icon-api.svg');
}

.icon-close {
    --svg: url('/static/svg/close.svg');
}

.icon-expand {
    --svg: url('/static/svg/expand.svg');
}

.icon-logout {
    --svg: url('/static/svg/logout.svg');
}

.icon-moon {
    --svg: url('/static/svg/moon-2.svg');
    mask-size: 120%;
}

.icon-sun {
    --svg: url('/static/svg/sun-3.svg');
}

.icon-search {
    --svg: url('/static/svg/search.svg');
}

.icon-search2 {
    --svg: url('/static/svg/search2.svg');
}

.icon-reset {
    --svg: url('/static/svg/reset.svg');
}

.icon-download {
    --svg: url('/static/svg/download.svg');
}

.icon-user {
    --svg: url('/static/svg/user.svg');
}

.icon-user-2 {
    --svg: url('/static/svg/user-2.svg');
}

.icon-add {
    --svg: url('/static/svg/add-circle.svg');
}

.icon-flame {
    --svg: url('/static/svg/flame.svg');
}

.icon-lock-closed {
    --svg: url('/static/svg/lock-closed.svg');
}

.icon-lock-open {
    --svg: url('/static/svg/lock-open.svg');
}

.icon-lock-closed-outline {
    --svg: url('/static/svg/lock-closed-outline.svg');
}

.icon-api {
    --svg: url('/static/svg/api.svg');
}

.icon-calculator {
    --svg: url('/static/svg/calculator.svg');
}

.icon-copy {
    --svg: url('/static/svg/copy.svg');
}

.icon-copy-link {
    --svg: url('/static/svg/copy-link.svg');
}

.icon-copy2 {
    --svg: url('/static/svg/copy2.svg');
}

.icon-announcements {
    --svg: url('/static/svg/announcements.svg');
}

.icon-clock {
    --svg: url('/static/svg/clock.svg');
}

.icon-filter {
    --svg: url('/static/svg/filter.svg');
}

.icon-sort {
    --svg: url('/static/svg/sort.svg');
}

.icon-list-view {
    --svg: url('/static/svg/list-view.svg');
}

.icon-grid-view {
    --svg: url('/static/svg/grid-view.svg');
}

// Arrows
.icon-chevron-down {
    --svg: url('/static/svg/chevron-down.svg');
}

.icon-arrow-right {
    --svg: url('/static/svg/arrow-right.svg');
}

.icon-new-css {
    --svg: url('/static/svg/arrow-right.svg');
}

.icon-arrow-down {
    --svg: url('/static/svg/arrow-right.svg');
    transform: rotate(90deg) translateY(50%);
}

.icon-arrow-long {
    --svg: url('/static/svg/arrow-long.svg');
    height: 20px;
    width: 100%;
    mask-position: right center;
}

.icon-chevron-arrow-up {
    --svg: url('/static/svg/chevron-arrow-up.svg');
}

.icon-chevron-arrow-down {
    --svg: url('/static/svg/chevron-arrow-up.svg');
    transform: rotate(180deg) translateY(50%);
}

.icon-link {
    --svg: url('/static/svg/icon-link.svg');
}

//social
.icon-twitter {
    --svg: url('/static/svg/twitter-2.svg');
    mask-size: 110%;
    background-size: 110%;
}

.icon-linkedin {
    --svg: url('/static/svg/linkedin.svg');
}

.icon-youtube {
    --svg: url('/static/svg/youtube.svg');
}

.icon-google {
    --svg: url('/static/svg/google.svg');
}

.icon-github {
    --svg: url('/static/svg/github.svg');
}

.icon-gitbook {
    --svg: url('/static/svg/gitbook.svg');
}

.icon-discord {
    --svg: url('/static/svg/discord.svg');
}

.icon-unlink {
    --svg: url('/static/svg/unlink.svg');
}

.icon-swap {
    --svg: url('/static/svg/swap.svg');
}

.icon-columns {
    --svg: url('/static/svg/icon-columns.svg');
}

.icon-xcircle {
    --svg: url('/static/svg/icon-xcircle.svg');
}

.icon-beehive {
    --svg: url('/static/svg/beehive.svg');
}

.icon-facebook {
    --svg: url('/static/svg/facebook.svg');
}

.icon-instagram {
    --svg: url('/static/svg/instagram.svg');
}

.icon-spotify {
    --svg: url('/static/svg/spotify.svg');
}

.icon-bloomberg {
    --svg: url('/static/svg/bloomberg.svg');
}

.icon-coinbase {
    --svg: url('/static/svg/coinbase.svg');
}

.icon-kraken {
    --svg: url('/static/svg/kraken.svg');
}

.icon-kraken-full {
    --svg: url('/static/img/kraken-light.png');
}

.icon-telegram {
    --svg: url('/static/svg/telegram.svg');
}

.icon-reddit {
    --svg: url('/static/svg/reddit.svg');
}

.icon-forum {
    --svg: url('/static/svg/forum.svg');
}

.icon-share {
    --svg: url('/static/svg/share.svg');
}

.icon-elipsis {
    --svg: url('/static/svg/elipsis.svg');
}

.icon-power {
    --svg: url('/static/svg/power.svg');
}

.icon-wallet {
    --svg: url('/static/svg/wallet.svg');
}

.icon-calendar {
    --svg: url('/static/svg/calendar.svg');
}

.icon-candy {
    --svg: url('/static/svg/candy.svg');
}

.icon-lock-0 {
    --svg: url('/static/svg/icon-lock-0.svg');
}

.icon-lock-1 {
    --svg: url('/static/svg/icon-lock-1.svg');
}

.icon-angle-right {
    --svg: url('/static/svg/angle-right.svg');
}

.icon-chart-pie {
    --svg: url('/static/svg/chart-pie.svg');
}

.icon-check-circle {
    --svg: url('/static/svg/check-circle.svg');
}

.icon-coins {
    --svg: url('/static/svg/coins.svg');
}

.icon-drop {
    --svg: url('/static/svg/drop.svg');
}

.icon-drop-circle {
    --svg: url('/static/svg/drop-circle.svg');
}

.icon-plus {
    --svg: url('/static/svg/plus.svg');
}

.icon-minus {
    --svg: url('/static/svg/minus.svg');
}

.icon-arrow-left {
    --svg: url('/static/svg/arrow-left.svg');
}

.icon-check {
    --svg: url('/static/svg/check.svg');
}

.icon-transaction {
    --svg: url('/static/svg/transaction.svg');
}

.icon-info-circle {
    --svg: url('/static/svg/info-circle.svg');
}

.icon-info-circle-plain {
    --svg: url('/static/svg/info-circle-plain.svg');
}

.icon-claimed-tag {
    --svg: url('/static/svg/claimed-tag.svg');
}

.icon-cross {
    --svg: url('/static/svg/cross.svg');
}

.icon-vault {
    --svg: url('/static/svg/vault.svg');
}

.icon-hand {
    --svg: url('/static/svg/hand.svg');
}

.icon-mouse {
    --svg: url('/static/svg/mouse.svg');
}

.icon-multicoin {
    --svg: url('/static/svg/multicoin.svg');
}

.icon-stake-now-text {
    --svg: url('/static/svg/stake-now-text.svg');
}

.icon-exclamation-circle {
    --svg: url('/static/svg/exclamation-circle.svg');
}

.icon-exclamation-triangle {
    --svg: url('/static/svg/exclamation-triangle.svg');
}

.icon-external-link-alt {
    --svg: url('/static/svg/external-link-alt.svg');
}

.icon-sr {
    --svg: url('/static/svg/icon-sr.svg');
}

.icon-cosmos {
    --svg: url('/static/svg/cosmos.svg');
}

.icon-eth {
    --svg: url('/static/svg/ethereum.svg');
}

.icon-dual-staking {
    --svg: url('/static/svg/dual-staking.svg');
}

.icon-dropdown {
    --svg: url('/static/svg/dropdown.svg');
}

.icon-dropdown-up {
    --svg: url('/static/svg/dropdown.svg');
    transform: rotate(180deg);
}

.icon-cube {
    --svg: url('/static/svg/cube-icon.svg');
}

.icon-coinpaprika {
    --svg: url('/static/svg/coinpaprika-icon.svg');
}

.icon-chat {
    --svg: url('/static/svg/chat-icon.svg');
}

.icon-github {
    --svg: url('/static/svg/github-icon.svg');
}

.icon-newspaper {
    --svg: url('/static/svg/newspaper-icon.svg');
}

.icon-globe {
    --svg: url('/static/svg/globe-icon.svg');
}

.icon-code {
    --svg: url('/static/svg/code-icon.svg');
}

.icon-document {
    --svg: url('/static/svg/document-icon.svg');
}

.icon-external {
    --svg: url('/static/svg/external-icon.svg');
}

.icon-chevron-angle-down {
    --svg: url('/static/svg/chevron-angle-down.svg');
}

.icon-all-validator {
    --svg: url('/static/svg/all-validator.svg');
}

.icon-settings {
    --svg: url('/static/svg/settings.svg');
}

.icon-warning {
    --svg: url('/static/svg/warning.svg');
}

.icon-arrow-withdraw {
    --svg: url('/static/svg/arrow-withdraw.svg');
}

.icon-arrow-stake {
    --svg: url('/static/svg/arrow-stake.svg');
}

.icon-arrow-unbound {
    --svg: url('/static/svg/arrow-unbound.svg');
}

.icon-babylon {
    --svg: url('/static/svg/icon-babylon.svg');
}

.icon-angle-sort-asc {
    --svg: url('/static/svg/angle-sort-asc.svg');
}

.icon-angle-sort-desc {
    --svg: url('/static/svg/angle-sort-desc.svg');
}

.icon-navbar {
    --svg: url('/static/svg/navbar.svg');
}

.icon-discover {
    --svg: url('/static/svg/discover.svg');
}

.icon-discover-thin {
    --svg: url('/static/svg/discover-thin.svg');
}

.icon-api-letter {
    --svg: url('/static/svg/api-letter.svg');
}

.icon-blue-check {
    --svg: url('/static/svg/blue-check.svg');
}

.icon-red-cross {
    --svg: url('/static/svg/red-cross.svg');
}

.icon-green-check-circle {
    --svg: url('/static/svg/green-check-circle.svg');
}

.icon-green-trophy {
    --svg: url('/static/svg/green-trophy.svg');
}

.icon-lightning {
    --svg: url('/static/svg/lightning.svg');
}

.icon-arrow-up-down {
    --svg: url('/static/svg/arrow-up-down.svg');
}

.icon-triangle-up {
    --svg: url('/static/svg/triangle-up.svg');
}

.icon-triangle-down {
    --svg: url('/static/svg/triangle-down.svg');
}
