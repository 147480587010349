@keyframes FadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fadeIn {
    opacity: 0;
    animation: FadeIn 1s ease-in-out 1s forwards;
}

@keyframes Rotate {
    to {
        transform: rotate(360deg);
    }
}

.rotate {
    animation: Rotate 1s ease infinite forwards;
}
