.stackedItems {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px 40px;
    width: 100%;

    .item {
        opacity: 1;
        transition: opacity 0.2s ease-in-out;

        &:hover {
            opacity: 0.75;
        }
    }
}
