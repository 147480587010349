.editableNumber {
    display: block;
    transition: opacity 0.2s ease-in-out;

    // Prevent from being overwritten by global styles
    .input {
        font-size: 14px;
        padding: 4px 16px;
        margin: 0;
        line-height: 1;
        color: var(--c-primary);
    }

    input[type='number'] {
        -moz-appearance: textfield;
    }

    .value {
        font-size: 14px;
        font-weight: bold;
        color: var(--c-primary);
        text-align: center;
        width: 100%;
        position: absolute;
        left: 0;
        top: 3px;
    }
}
