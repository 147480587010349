.scrollbarWithGradient {
    &.bg {
        // gradient
        &::after,
        &::before {
            // Forcing to overwrite global styles
            background: linear-gradient(90deg, rgba(239, 239, 239, 0) 0%, rgba(239, 239, 239, 1) 100%) !important;
        }

        &::before {
            // Forcing to overwrite global styles
            background: linear-gradient(90deg, rgba(36, 36, 36, 0) 0%, rgba(36, 36, 36, 1) 100%) !important;
        }
    }

    &.toggleGradient {
        &::after {
            opacity: 0;
        }

        &[data-theme='dark'] {
            &::before {
                opacity: 0;
            }
        }
    }

    &.horizontal {
        &::after,
        &::before {
            content: '';
            right: 0;
            z-index: 2;
            bottom: 5px;
        }

        .childrenWrap {
            overflow: visible;
            padding-right: 0;

            &.scrollable {
                padding-right: 60px;
                padding-bottom: 10px;
                overflow-x: hidden;

                // When switching a scrollbar, hold the scrollbar height
                // to avoid layout shifts
                & + .scrollPlaceholder {
                    width: 100%;
                    height: 5px;
                }

                &:hover {
                    overflow-x: auto;

                    & + .scrollPlaceholder {
                        height: 0;
                    }
                }
            }
        }
    }
}
