.cms {
    width: 100%;
    color: var(--c-contrast-6);

    @media (min-width: 768px) {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 0 40px;
    }

    i {
        font-style: italic;
    }

    p,
    td,
    li,
    i,
    a {
        line-height: 1.5;
        font-size: 16px;
    }

    p,
    i {
        margin-bottom: 16px;
    }

    p,
    td,
    li,
    ol,
    h2,
    h3,
    h4,
    h5,
    h6,
    figcaption,
    strong,
    i,
    b {
        color: var(--c-contrast-6);
    }

    figure {
        margin-bottom: 24px;
    }

    figcaption {
        margin-top: 16px;
        text-align: center;
        font-size: 14px;

        a {
            font-size: 12px;
        }
    }

    a {
        color: var(--c-primary);

        &:hover {
            text-decoration: underline;
        }
    }

    strong,
    b {
        font-weight: bold;
    }

    h2 {
        line-height: 1.25;

        @media (min-width: 768px) {
            grid-column: 1 / 2;
        }
    }

    & > *:not(h2) {
        @media (min-width: 768px) {
            grid-column: 2 / 3;
        }
    }

    h3 {
        line-height: 1.5;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    h4 {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    ul {
        list-style: outside;
        margin-bottom: 16px;
    }

    li {
        margin-left: 52px;
        margin-bottom: 8px;
    }

    ol {
        list-style: decimal;
        margin: 0 0 16px 0;
    }

    table {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 40px;

        tr {
            margin-bottom: 20px;
        }

        td {
            border: 1px solid var(--c-contrast-6);
            padding: 10px;

            &:first-child {
                width: 140px;
            }
        }
    }

    hr {
        opacity: 0;
        margin-bottom: 60px;
    }

    blockquote {
        border-left: 2px solid var(--c-contrast-2);
        padding: 16px;

        p,
        i,
        b,
        strong {
            color: var(--c-contrast-4);
            margin-bottom: 0;

            &:first-child {
                font-size: 20px;
                font-weight: bold;
                font-style: italic;
                margin-bottom: 8px;
            }
        }
    }

    code {
        background-color: var(--c-contrast-1);
        padding: 8px;
        border-radius: 4px;
        font-family: 'MaisonNeueMono';
        font-size: 12px;
        font-weight: bold;
    }

    pre {
        background-color: var(--c-contrast-1);
        border-radius: 16px;
        margin-bottom: 24px;

        code {
            width: 100%;
            display: block;
            padding: 16px;
            background-color: transparent;
        }
    }
}
