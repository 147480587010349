.timeDurationInput {
    .timeDurationWrap {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        gap: 4px;

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type='number'] {
            -moz-appearance: textfield;
        }

        .numericInput {
            display: flex;
            flex-direction: row;
            height: 26px;
            font-size: 14px;

            input {
                margin: 0;
                padding: 4px;
                border-radius: 0;
                box-sizing: border-box;
                font-size: 12px;
                height: 26px;

                &:focus::placeholder {
                    color: transparent;
                }
            }
        }

        &.error {
            outline: 1px solid var(--c-red);
            box-shadow: 0 0 10px var(--c-red);
        }

        .withSymbol {
            background-color: var(--c-grey-40);
            color: var(--c-lightest);
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 6px;
            font-weight: 700;
            min-width: 22px;
            width: 22px;
            max-width: 22px;
            font-size: 12px;
        }
    }

    .tooltip {
        margin-bottom: 24px;
    }
}
