.skeleton {
    &.contrast1 {
        --base-color: var(--c-contrast-1);
        --highlight-color: var(--c-contrast-2);
    }

    &.contrast2 {
        --base-color: var(--c-contrast-2);
        --highlight-color: var(--c-contrast-3);
    }

    --animation-duration: 1.5s;
    --animation-direction: normal;
    --pseudo-element-display: block;
    background-color: var(--base-color);
    width: 100%;
    border-radius: 0.25rem;
    display: inline-flex;
    line-height: 1;
    position: relative;
    user-select: none;
    overflow: hidden;
    z-index: 1;
    vertical-align: middle;

    &::after {
        content: '';
        display: var(--pseudo-element-display);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        background-repeat: no-repeat;
        background-image: linear-gradient(90deg, var(--base-color), var(--highlight-color), var(--base-color));
        transform: translateX(-100%);

        animation-name: skeleton;
        animation-direction: var(--animation-direction);
        animation-duration: var(--animation-duration);
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
    }

    @media (prefers-reduced-motion) {
        --pseudo-element-display: none;
    }
}

@keyframes skeleton {
    100% {
        transform: translateX(100%);
    }
}
