.searching {
    padding-left: 20px;
    overflow-y: auto;
    // Prevent overlap with the menu or control buttons in the bottom
    margin-bottom: 100px;

    .label {
        color: var(--c-contrast-4);
        font-weight: bold;
    }

    .results {
        padding: 20px 0 40px 0;

        .searchAsset {
            display: inline-block;
            margin: 0 40px 40px 0;
        }

        .link {
            color: var(--c-primary);
        }
    }
}

.withSearch {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
}
