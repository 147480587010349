:root {
    // spacing & shape
    --vh: calc(var(--v-height, 1vh) * 100);

    --p-page-sides: 5px;
    --p-page-top: 40px;

    @media (min-width: 1024px) {
        --p-page-sides: 80px;
    }

    --b-radius: 2px;

    // fonts
    // colors
    --c-ultra-light: #f7f7f7;
    --c-lightest: #efefef;
    --c-lighter: #e0e0e0;
    --c-light: #9e9e9e;
    --c-darkest: #242424;
    --c-darker: #303030;
    --c-dark: #4a4a4a;
    --c-mid: #757575;
    --c-background-grey: #f7f7f7;

    // colors for tailwind compatibility
    --ct-ultra-light: 247 247 247;
    --ct-lightest: 239 239 239;
    --ct-lighter: 224 224 224;
    --ct-light: 158 158 158;
    --ct-darkest: 36 36 36;
    --ct-darker: 48 48 48;
    --ct-dark: 74 74 74;
    --ct-mid: 117 117 117;
    --ct-background-grey: 247 247 247;

    --c-bg: var(--c-background-grey);
    --c-fg: var(--c-darkest);

    --c-blue: #023bff;
    --c-cyan: #4ec1f3;
    --c-magenta: #9c1bfa;
    --c-yellow: #fea73f;
    --c-yellow-green: #edee96;
    --c-orange: #e46f4c;
    --c-red: #ce414e;
    --c-green: #00a455;
    --c-black: #161616;

    --c-blue-lighter: #1f51ff;
    --c-blue-2: #7092ff;

    --c-primary: var(--c-blue);
    --c-secondary: var(--c-cyan);
    --c-primary-10: rgba(2, 59, 255, 0.1);

    --c-heading: var(--c-fg);
    --c-text: #7a7a7a;

    --c-wb: #ffffff;
    --c-bw: #161616;

    --c-grey-4: rgba(0, 0, 0, 0.04);
    --c-grey-8: rgba(0, 0, 0, 0.08);
    --c-grey-12: rgba(0, 0, 0, 0.12);
    --c-grey-20: rgba(0, 0, 0, 0.2);
    --c-grey-40: rgba(0, 0, 0, 0.4);
    --c-grey-60: rgba(0, 0, 0, 0.6);
    --c-grey-80: rgba(0, 0, 0, 0.8);

    --c-contrast-0: var(--c-lightest);
    --c-contrast-0a: var(--c-ultra-light);
    --c-contrast-1: var(--c-lighter);
    --c-contrast-2: var(--c-light);
    --c-contrast-3: var(--c-mid);
    --c-contrast-4: var(--c-dark);
    --c-contrast-5: var(--c-darker);
    --c-contrast-5a: var(--c-darker);
    --c-contrast-6: var(--c-darkest);

    // for tailwind
    --ct-contrast-0: var(--ct-lightest);
    --ct-contrast-0a: var(--ct-ultra-light);
    --ct-contrast-1: var(--ct-lighter);
    --ct-contrast-2: var(--ct-light);
    --ct-contrast-3: var(--ct-mid);
    --ct-contrast-4: var(--ct-dark);
    --ct-contrast-5: var(--ct-darker);
    --ct-contrast-5a: var(--ct-darker);
    --ct-contrast-6: var(--ct-darkest);

    --c-success: var(--c-cyan);
    --c-positive: var(--c-cyan);

    --c-error: var(--c-magenta);
    --c-negative: var(--c-magenta);

    --c-cta-base: var(--c-blue);
    --c-cta-accent: var(--c-cyan);

    --overflow-gradient: linear-gradient(90deg, rgba(239, 239, 239, 0) 0%, rgba(239, 239, 239, 1) 100%);

    --overflow-gradient-pre: linear-gradient(-90deg, rgba(239, 239, 239, 0) 0%, rgba(239, 239, 239, 1) 100%);

    --overflow-gradient-vert: linear-gradient(180deg, rgba(239, 239, 239, 0) 0%, rgba(239, 239, 239, 1) 100%);
    // Vertical gradient on top of --c-contrast-1 background
    --overflow-gradient-vert2: linear-gradient(180deg, rgba(224, 224, 224, 0) 0%, rgba(224, 224, 224, 1) 100%);

    --conic-gradient: conic-gradient(
        var(--c-green) 0%,
        var(--c-yellow) 16%,
        var(--c-red) 32%,
        var(--c-magenta) 48%,
        var(--c-blue) 64%,
        var(--c-cyan) 80%,
        var(--c-green) 100%
    );

    --linear-gradient: linear-gradient(227deg, var(--c-blue), var(--c-magenta));

    --c-overlay-bg: rgba(255, 255, 255, 0.8);
}

*[data-theme='dark'] {
    --c-bg: var(--c-darkest);
    --c-fg: var(--c-lightest);

    --c-primary: var(--c-cyan);
    --c-primary-10: rgba(78, 193, 243, 0.1);
    --c-secondary: var(--c-blue);

    --c-text: #cccccc;

    --c-wb: #161616;
    --c-bw: #ffffff;

    --c-grey-4: rgba(239, 239, 239, 0.04);
    --c-grey-8: rgba(239, 239, 239, 0.08);
    --c-grey-20: rgba(239, 239, 239, 0.2);
    --c-grey-40: rgba(239, 239, 239, 0.4);
    --c-grey-60: rgba(239, 239, 239, 0.6);
    --c-grey-80: rgba(239, 239, 239, 0.8);

    --c-contrast-0: var(--c-darkest);
    --c-contrast-0a: var(--c-darkest);
    --c-contrast-1: var(--c-darker);
    --c-contrast-2: var(--c-dark);
    --c-contrast-3: var(--c-light);
    --c-contrast-4: var(--c-lighter);
    --c-contrast-5: var(--c-lightest);
    --c-contrast-5a: var(--c-lighter);
    --c-contrast-6: var(--c-lightest);

    // for tailwind
    --ct-contrast-0: var(--ct-darkest);
    --ct-contrast-0a: var(--ct-darkest);
    --ct-contrast-1: var(--ct-darker);
    --ct-contrast-2: var(--ct-dark);
    --ct-contrast-3: var(--ct-light);
    --ct-contrast-4: var(--ct-lighter);
    --ct-contrast-5: var(--ct-lightest);
    --ct-contrast-5a: var(--ct-lighter);
    --ct-contrast-6: var(--ct-lightest);

    --c-cta-base: var(--c-cyan);
    --c-cta-accent: var(--c-blue);

    --overflow-gradient: linear-gradient(90deg, rgba(36, 36, 36, 0) 0%, rgba(36, 36, 36, 1) 100%);

    --overflow-gradient-pre: linear-gradient(-90deg, rgba(36, 36, 36, 0) 0%, rgba(36, 36, 36, 1) 100%);

    --overflow-gradient-vert: linear-gradient(180deg, rgba(36, 36, 36, 0) 0%, rgba(36, 36, 36, 1) 100%);
    // Vertical gradient on top of --c-contrast-1 background
    --overflow-gradient-vert2: linear-gradient(180deg, rgba(48, 48, 48, 0) 0%, rgba(48, 48, 48, 1) 100%);

    --c-overlay-bg: rgba(0, 0, 0, 0.8);
}

@layer components {
    .bg-gradient-sr-light {
        @apply bg-gradient-to-bl from-cyan/20 via-magenta/20 to-yellow-sr/20;
    }

    .bg-gradient-sr-light\/10 {
        @apply bg-gradient-to-bl from-cyan/10 via-magenta/10 to-yellow-sr/10;
    }

    .bg-gradient-sr {
        @apply bg-gradient-to-bl from-cyan via-magenta to-yellow-sr;
    }

    .bg-gradient-sr2 {
        @apply bg-gradient-to-br from-cyan to-yellow-sr;
    }

    .bg-gradient-sr-on-white {
        position: relative;
        // color: white;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            -webkit-background-clip: text;
            background-clip: text;
            color: red;
            // color: transparent;
            // @apply bg-gradient-sr;
        }
    }

    @supports not (-webkit-backdrop-filter: none) {
        .speaker-gradient {
            &::before {
                @apply absolute inset-0;
                content: '';
                background-color: var(--c-yellow);
                opacity: 1;
                z-index: 10;
                transition: opacity 0.4s ease;
                mix-blend-mode: color;
            }

            &:hover {
                &::before {
                    opacity: 0;
                }
            }
        }
    }

    .blurred-overlay {
        &::before {
            @apply absolute top-0 left-0;
            content: '';
            width: 100%;
            height: 100%;
            z-index: 1; /* Ensure this is above your image but below the content */
            backdrop-filter: blur(3px);
            transition: opacity 0.4s ease;
        }

        &::after {
            @apply absolute inset-0 bg-shade-darkest opacity-80 top-0 left-0;
            content: '';
            width: 100%;
            height: 100%;
            transition: all 0.4s ease;
        }

        &:hover {
            &::before {
                opacity: 0;
            }
            &::after {
                @apply bg-yellow-sr opacity-40;
            }
        }
    }

    .text-scroll {
        @apply inline-flex min-w-full;
        animation: scroll-left 20s linear infinite alternate;
    }

    .text-scroll[data-direction='alt'] {
        animation-name: scroll-right;
    }

    @keyframes scroll-left {
        from {
            transform: translateX(0);
        }
        to {
            transform: translateX(-100%);
        }
    }

    @keyframes scroll-right {
        from {
            transform: translateX(-100%);
        }
        to {
            transform: translateX(0);
        }
    }

    .activeMarker {
        box-shadow: 0 0 0 2px rgba(65, 201, 136, 0.4);
        flex-shrink: 0;
        transition: all 300ms ease-in-out;
        animation: activeMarkerBlink 3s infinite;
    }

    @keyframes activeMarkerBlink {
        0% {
            box-shadow: 0 0 0 2px rgba(65, 201, 136, 0.4);
            background: rgb(65, 201, 136);
        }
        50% {
            box-shadow: 0 0 0 2px rgba(65, 201, 136, 0);
            background: rgba(65, 201, 136, 0.5);
        }
        100% {
            box-shadow: 0 0 0 2px rgba(65, 201, 136, 0.4);
            background: rgb(65, 201, 136);
        }
    }

    .standbyMarker {
        box-shadow: 0 0 0 2px rgba(254, 167, 63, 0.4);
        background: var(--c-yellow);
        flex-shrink: 0;
        transition: all 300ms ease-in-out;
    }

    .inactiveMarker {
        box-shadow: 0 0 0 2px rgba(206, 65, 78, 0.4);
        background: var(--c-red);
        flex-shrink: 0;
        transition: all 300ms ease-in-out;
    }

    .bg-terminalBlock {
        background: radial-gradient(
                60% 200% at 100% 0%,
                rgba(156, 27, 250, 0.8) 0%,
                rgba(78, 193, 243, 0.8) 50%,
                rgba(255, 255, 255, 0) 99.5%
            ),
            radial-gradient(
                60.67% 148.16% at 36.83% -34.56%,
                rgba(254, 167, 63, 1) 0%,
                rgba(254, 167, 63, 0.3) 40%,
                rgba(255, 255, 255, 0) 99.5%
            );
    }

    .coin-drop {
        animation: coin-drop 1s ease-in-out forwards;
    }
    .coin-drop2 {
        animation: coin-drop2 1s ease-in-out 200ms forwards;
    }

    @keyframes coin-drop {
        0% {
            transform: translate(0, 0);
        }
        50% {
            transform: translate(-50px, -50px);
        }
        100% {
            transform: translate(-90px, 330px);
        }
    }

    @keyframes coin-drop2 {
        0% {
            transform: translate(0, 0);
        }
        50% {
            transform: translate(-50px, -50px);
        }
        100% {
            transform: translate(-90px, 230px);
        }
    }
}
