@mixin aspect-ratio {
    --width: 1;
    --height: 1;
    position: relative;
    &::before {
        display: block;
        content: '';
        width: 100%;
        padding-top: calc(var(--height) / var(--width) * 100%);
    }
}

.sixteen-nine {
    @include aspect-ratio;
    --width: 16;
    --height: 9;
}

.nine-sixteen {
    @include aspect-ratio;
    --width: 9;
    --height: 16;
}

.three-four {
    @include aspect-ratio;
    --width: 3;
    --height: 4;
}

.square {
    @include aspect-ratio;
    --width: 1;
    --height: 1;
}

.banner-horizontal {
    @include aspect-ratio;
    --width: 740;
    --height: 90;
}
