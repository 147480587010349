.overlay {
    z-index: 80;
    position: absolute;
    background: var(--c-bg);
    pointer-events: all;
    inset: 0;
    width: 100%;
    height: 100%;
    clip-path: inset(0 0 100% 0);
    animation: Reveal 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) forwards;
    overflow: auto;

    &.mobileOverlay {
        user-select: none;
    }

    &.closeAnimation {
        animation: HideMenu 0.4s cubic-bezier(0.47, 0, 0.745, 0.715) forwards;
    }

    @keyframes Reveal {
        to {
            clip-path: inset(0 0 0 0);
        }
    }

    @keyframes HideMenu {
        from {
            clip-path: inset(0 0 0 0);
        }

        to {
            clip-path: inset(0 0 100% 0);
        }
    }

    .wrap {
        width: 100%;
        height: 100%;
    }
}
