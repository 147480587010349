.youtubeVideo {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;

    h2 {
        margin: auto;
        margin-bottom: 45px;
    }

    .video {
        margin: auto;
        width: 100%;
    }
}
