.options {
    scrollbar-width: 5px;

    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    ::-moz-scrollbar-track {
        background-color: transparent;
    }
}
