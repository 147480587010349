.pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    margin: 20px 0;

    .pages {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;

        .btn {
            border: none;
            font-size: 16px;
            color: var(--c-light);
            font-weight: bold;
        }

        .page {
            font-size: 16px;
            color: var(--c-primary);
            font-weight: bold;
        }
    }
}

.paginationConfig {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 4;
    padding: 24px 0 4px 0;
}

.loadMoreCentered {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    padding: 24px 0;

    .btn {
        width: max-content;
        padding: 12px 40px;
    }
}

.pageSizeDropdownWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;

    .pageSizeDropdown {
        width: fit-content;
        max-width: 60px;
    }

    .label {
        color: var(--c-contrast-3);
        font-size: 12px;
        font-weight: bold;
        white-space: nowrap;
    }
}
