.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(36, 36, 36, 0.8);
    display: flex;
    opacity: 0;
    visibility: hidden;
    align-items: center;
    justify-content: center !important;
    z-index: 999;

    transition: all 200ms ease-in-out;

    [data-theme='dark'] & {
        background-color: rgba(60, 60, 60, 0.8);
    }

    .modal {
        translate: 0 -20px;
        transition: all 200ms ease-in-out;
    }

    &.show {
        opacity: 1;
        visibility: visible;

        .modal {
            translate: 0 0;
        }
    }
}

.modal {
    background-color: var(--c-contrast-0);
    padding: 70px 90px 40px 90px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    position: relative;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;

    &.hideClose {
        padding: 40px 90px 40px 90px;

        .closeButton {
            display: none;
        }
    }

    .closeButtonWrap {
        position: absolute;
        cursor: pointer;
        top: 0;
        right: 0;
        padding: 20px;
        z-index: 1;
    }

    .closeButton {
        width: 12px;
        background-color: var(--c-ultra-light);
    }

    @media (max-width: 767px) {
        padding: 40px 20px;
        max-width: 90%;
        max-height: 90%;
    }

    .header {
        position: relative;

        .title {
            font-size: 18px;
            font-weight: 600;
            margin: 0;
            text-align: center;
        }
    }
}

.content {
    margin-top: 0px;
}
