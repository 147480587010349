.subscribeForm {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;

    form {
        flex: 1;
        display: grid;
        grid-template-columns: 1fr 40%;
        margin-bottom: 0;
        padding-bottom: 0;
        row-gap: 20px;
        max-width: 520px;
    }

    input[type='email'] {
        width: 100%;
        margin: 0;
        padding: 16px 20px;
        border-radius: 0 0 0 8px;
        font-size: 18px;
        letter-spacing: 0.24px;
        color: var(--c-fg);
        background-color: white;

        @media (max-width: 599px) {
            border-radius: 0;
        }
    }

    button[type='submit'] {
        height: 100%;
        width: 100%;
        background-color: var(--c-blue);
        color: var(--c-lightest);
        margin: auto 0;
        flex: 1;
        font-size: 16px;
        border-radius: 0 0 8px 0;
        border-color: var(--c-blue);
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
        transition:
            box-shadow 0.2s ease-in-out,
            opacity 0.4s ease-in-out,
            transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);

        &:hover {
            box-shadow: 0 0 20px rgba(var(--c-fg), 0.45);
        }

        @media (max-width: 599px) {
            border-radius: 0;
        }
    }

    .sending {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .error {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 150%);
        text-align: center;
        bottom: 0;
        color: var(--c-red);
        font-weight: bold;
        letter-spacing: 0.2px;
    }

    .success {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-weight: bold;
        color: var(--c-contrast-4);
        line-height: 1.4;
        width: 100%;
    }

    &[data-sending='true'],
    &[data-success='true'] {
        form {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
        }
    }

    &[data-error='true'] input[type='email'] {
        border: 1px solid var(--c-red);
    }

    &[data-theme='dark'] {
        input[type='email'] {
            background-color: var(--c-darkest);
        }
    }
}
