.slider {
    @mixin sliderThumbStyles {
        -webkit-appearance: none;
        appearance: none;
        background-color: var(--c-contrast-3);
        width: 16px;
        height: 16px;
        border-radius: 50%;
        cursor: pointer;
        border: none;
    }

    input[type='range'] {
        padding: 0;
        margin: 0;
        border-radius: 40px;
        cursor: pointer;
        height: 16px;

        &::-webkit-slider-thumb {
            @include sliderThumbStyles;
        }

        &::-moz-range-thumb {
            @include sliderThumbStyles;
        }

        &::-ms-thumb {
            @include sliderThumbStyles;
        }
    }
}
