.box {
    display: inline-flex;
    flex: 1;
}

.bg {
    display: none;
}

.fg {
    flex: 1;
    padding: clamp(24px, 1.5vw, 32px);
    border: solid 2px var(--c-grey-20);
    background-color: var(--c-bg);
    transition: background-color 0.2s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 240px;
    width: 100%;
}

.box[data-theme='default'] {
    .fg {
        border: solid 2px var(--c-grey-80);
    }
    .bg {
        display: flex;
        border: solid 2px var(--c-grey-80);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: var(--c-darkest);
        animation: BoxIn 0.4s ease-out forwards;
    }
    @keyframes BoxIn {
        from {
            transform: none;
        }
        to {
            transform: translate(-4px, 4px);
        }
    }
}
