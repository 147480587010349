div.react-datepicker {
    display: flex;
    flex-direction: row;
    font-family: var(--font-interTight);
    border: none;
    border-radius: 8px;
    background: var(--c-contrast-1);

    .react-datepicker__header {
        background: var(--c-contrast-1);
        border: none;

        .react-datepicker__day-name {
            color: var(--c-contrast-3);
            font-size: 12px;
        }
    }

    .react-datepicker__day {
        margin: 0;
        width: 40px;
        height: 40px;
        line-height: 40px;
        color: var(--c-contrast-4);
        border-radius: 0;
        font-size: 14px;

        &:hover {
            background: var(--c-primary);
            color: var(--c-contrast-1);
        }

        &.react-datepicker__day--in-range:not(.react-datepicker__day--range-start):not(
                .react-datepicker__day--range-end
            ) {
            background: rgba(82, 150, 255, 0.2) !important;
            color: var(--c-contrast-4) !important;

            &:hover {
                background: var(--c-primary) !important;
                color: var(--c-contrast-1) !important;
            }
        }

        &.react-datepicker__day--range-start,
        &.react-datepicker__day--range-end {
            background: var(--c-primary);
            color: var(--c-contrast-1);
        }

        &.react-datepicker__day--outside-month {
            height: 0 !important;
            opacity: 0;
        }
    }

    .react-datepicker__month-container {
        background: var(--c-contrast-1);
        border-radius: 8px;
        height: 324px;

        .react-datepicker__day-names {
            padding-left: 0 24px;

            .react-datepicker__day-name {
                width: 40px;
                margin: 0;

                [data-theme='dark'] & {
                    color: var(--c-contrast-4);
                }
            }
        }

        .react-datepicker__month {
            padding: 0 24px;
            background: var(--c-contrast-1);

            .react-datepicker__day {
                [data-theme='dark'] & {
                    color: var(--c-contrast-4);
                }

                &.react-datepicker__day--weekend:not(.react-datepicker__day--range-start):not(
                        .react-datepicker__day--range-end
                    ) {
                    color: var(--c-red);
                }

                &.react-datepicker__day--disabled {
                    color: var(--c-contrast-2);
                }

                &.react-datepicker__day--selected {
                    background: var(--c-primary);
                    color: var(--c-lightest);
                }

                &.react-datepicker__day--keyboard-selected {
                    background: none;
                }
            }
        }
    }
}
