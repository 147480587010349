.brella-modal,
.ticketing-root {
    button {
        font-size: inherit !important;
    }

    input {
        font-weight: inherit !important;
        padding: inherit !important;
        margin: inherit !important;
        text-align: left !important;
        font-size: inherit !important;
        width: inherit !important;
        height: inherit !important;
        font-family: inherit !important;
        color: var(--c-darkest);
        background-color: inherit !important;
    }

    input[type='checkbox'] {
        background-color: inherit !important;
        text-align: inherit !important;
        width: 16px !important;
        height: 16px !important;
        margin-right: 4px !important;
        -webkit-appearance: auto !important;
        -moz-appearance: auto !important;
        appearance: auto !important;
    }

    input.discount-code-input {
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
        font-size: 14px !important;
        padding: 9px 8px !important;
    }

    input.ticket-list-item-ticket-amount-input {
        border: var(--brella-widget-input-border) !important;
        height: 30px !important;
        padding: 0 12px !important;
        width: 66px !important;
    }

    .custom-forms-container input {
        padding: 9px 8px !important;
    }

    .purchaser-info-container input {
        box-sizing: border-box !important;
        width: 100% !important;
    }

    input[type='checkbox'],
    input[type='radio'] {
        accent-color: var(--brella-color-ticketing-primary-600) !important;
        margin: 0 6px 0 0 !important;
    }

    [data-theme='dark'] & {
        background-color: var(--c-contrast-1);
        border-color: var(--c-contrast-2);
        .ticketing-header,
        .ticketing-summary-container,
        .summary-order-summary-text,
        .summary-subtotal-container,
        .custom-forms-container,
        .custom-form-title,
        .purchaser-info-container,
        .ticketing-question-container,
        .ticketing-footer-container,
        textarea,
        .custom-forms-terms-and-policy,
        input,
        .discount-code-apply-button,
        .ticket-list-container,
        .ticket-list-item-container {
            background-color: var(--c-contrast-1) !important;
            border-color: var(--c-contrast-2) !important;
            color: var(--c-contrast-5) !important;
        }

        .brella-widget-close {
            &::before,
            &::after {
                background-color: var(--c-contrast-5);
            }
        }

        input {
            color: var(--c-contrast-5) !important;
        }
    }
}
