.iconStack {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .imgWrap {
        border-radius: 50%;

        img {
            object-fit: contain;
            object-position: center;
            border-radius: 50%;
        }
    }
}
