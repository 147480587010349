.tag {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px;
    border: 1px solid var(--c-contrast-1);
    background-color: var(--c-contrast-1);
    border-radius: 20px;
    padding: 5px 16px;
    line-height: 1;

    .label {
        color: var(--c-contrast-4);
        font-size: 12px;
        font-weight: bold;
        white-space: nowrap;
    }

    &.yellow {
        border: 1px solid var(--c-yellow);

        .label {
            color: var(--c-yellow);
        }
    }

    &.minimal {
        background-color: transparent;
        border: 1px solid var(--c-contrast-4);
    }

    .icon {
        width: 12px;
        height: 12px;
    }
}
