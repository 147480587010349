.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .header {
        font-size: 40px;
        font-weight: 900;
        color: var(--c-contrast-5);
        text-align: center;
        width: 100%;
        font-family: var(--font-interTight);
        margin-bottom: 10px;
        letter-spacing: 2px;

        @media (max-width: 767px) {
            letter-spacing: 0px;
            font-size: 26px;
        }
    }

    .text {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        color: var(--c-contrast-4);
        text-align: center;
        width: 100%;
        margin-top: 10px;
        max-width: 600px;

        @media (max-width: 767px) {
            max-width: 300px;
            font-size: 14px;
        }
    }

    .insider {
        font-weight: 700;
        font-style: italic;
    }

    .footerLine {
        margin-top: 36px;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        color: var(--c-contrast-3);
    }

    .companies {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;

        & > img {
            width: 100px;
            height: 18px;
        }
    }

    .formContainer {
        position: relative;
        width: 100%;

        .sending {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        .success {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-weight: bold;
            color: var(--c-dark);
            line-height: 1.4;
            height: 57px;
            width: 100%;
            text-align: center;
        }

        .formWrapper {
            width: 100%;
            display: flex;
            max-width: none;
            justify-content: center;
        }

        form {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: row;
            justify-content: center;
            margin-top: 32px;
            align-items: center;
            max-width: 520px;

            @media (max-width: 599px) {
                flex-direction: column;
            }

            & input[type='email'] {
                height: 56px;
            }

            & button[type='submit'] {
                min-width: 150px;
                max-width: 150px;
                min-height: 56px;
                max-height: 56px;
            }
        }

        .error {
            left: 50%;
            font-size: 14px;
            transform: translate(-50%, 120%);
            width: 100%;
        }
    }
}
