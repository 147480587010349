body,
p,
span,
div,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-style: normal;
}

h1 {
    font-weight: 800;
    font-size: 48px;
    line-height: 1.2;
}

h2 {
    font-weight: 700;
    font-size: 38px;
    color: var(--c-contrast-4);
    line-height: 1.2;
}

h3 {
    font-weight: 700;
    font-size: 28px;
    line-height: 1.2;
}

h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 1.2;
}

h5 {
    font-weight: 700;
    font-size: 20px;
    line-height: 1.2;
}

h6 {
    font-weight: bold;
    line-height: 1;
}

.text-description {
    color: var(--c-contrast-3);
    font-size: 16px;
    line-height: 1.5;
}

.text-dark {
    color: var(--c-darkest);
    -webkit-text-stroke: 2px var(--c-darkest);
    line-height: 1;
}

.text-outline {
    color: var(--c-bg);
    -webkit-text-stroke: 2px var(--c-fg);
    line-height: 1;
}

.text-outline-blue {
    color: var(--c-bg);
    -webkit-text-stroke: 2px var(--c-blue);
    line-height: 1;
}

.text-outline-gradient {
    color: var(--c-bg);
    line-height: 1;

    -webkit-text-stroke: 3px transparent;

    background: var(--conic-gradient);
    background-size: 100% 100%;
    background-clip: text;
    // animation: AnimateGradient 24s ease infinite;
}

@keyframes AnimateGradient {
    0% {
        background-position: 0% 96%;
    }

    50% {
        background-position: 100% 5%;
    }

    100% {
        background-position: 0% 96%;
    }
}
