@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~react-image-gallery/styles/scss/image-gallery.scss';
@import 'styles/reset';
@import 'styles/vars';
@import 'styles/typography';
@import 'styles/forms';
@import 'styles/icons';
@import 'styles/animations';
@import 'styles/aspectRatio';
@import 'styles/ease';
@import 'styles/cms';
@import 'styles/nprogress';
@import 'styles/brella';
@import 'styles/react-datepicker';

html {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    background-color: var(--c-darkest);
    // overscroll-behavior was observed blocking vertical scrolling on some versions of Firefox
    // when the content (expanding the page height) is loaded dynamically (e.g. archive page)
    // overscroll-behavior: none;
}

body {
    font-size: 16px;
    line-height: 1.6;
    color: var(--c-fg);
    background: var(--c-bg);
    // transition: background-color 0.4s ease;
    width: 100%;
    min-height: var(--vh);
    overflow-x: hidden;
    overflow-y: auto;
    // overscroll-behavior was observed blocking vertical scrolling on some versions of Firefox
    // when the content (expanding the page height) is loaded dynamically (e.g. archive page)
    // overscroll-behavior: none;
}

strong {
    font-weight: bold;
}

* {
    position: relative;
    box-sizing: border-box;
}

::selection {
    background-color: var(--c-blue);
    color: var(--c-lightest);
    text-shadow: none;
    opacity: 1;
}

.container {
    margin: auto;
    width: 100%;
    max-width: 1200px;
    text-align: left;
}

.loader {
    --color1: var(--c-cta-accent);
    --color2: var(--c-cta-base);

    display: block;
    width: var(--size, 24px);
    height: var(--size, 24px);
    transform: rotate(0deg);
    border-radius: 50%;
    border: 2px solid var(--color1);
    transform-origin: center center;
    animation: Rotate 1s ease infinite forwards;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 8px;
        height: 8px;
        background-color: var(--color2);
        border-radius: 50%;
    }
}

.scroll-container-x {
    width: 100%;

    & > div {
        width: 100%;
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;

        &::-webkit-scrollbar {
            display: none;
            scrollbar-width: none;
            -ms-overflow-style: none;
        }
    }

    &::after {
        content: '';
        position: absolute;
        top: -20px;
        right: 0;
        bottom: 0;
        width: 25%;
        max-width: 120px;
        pointer-events: none;
        z-index: 1;
        background: var(--overflow-gradient);
    }

    &.pre-overflow-gradient {
        &::before {
            content: '';
            position: absolute;
            top: -20px;
            left: 0;
            bottom: 0;
            width: 25%;
            max-width: 120px;
            pointer-events: none;
            z-index: 1;
            background: var(--overflow-gradient-pre);
        }
    }
}

.overflow-gradient-right {
    &::after,
    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 80px;
        pointer-events: none;
        background: linear-gradient(90deg, rgba(224, 224, 224, 0) 0%, rgba(224, 224, 224, 1) 100%);
        transition:
            opacity 0.2s ease,
            background-color 0.4s ease;
    }

    &::after {
        opacity: 1;
    }

    &::before {
        opacity: 0;
        background: linear-gradient(90deg, rgba(48, 48, 48, 0) 0%, rgba(48, 48, 48, 1) 100%);
    }

    &[data-theme='dark'] {
        &::after {
            opacity: 0;
        }

        &::before {
            opacity: 1;
        }
    }
}

// Scrollbars
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-moz-scrollbar {
    scrollbar-width: 8px;
}

::-webkit-scrollbar-track {
    background-color: var(--c-bg);
}

::-moz-scrollbar-track {
    background-color: var(--c-bg);
}

::-webkit-scrollbar-thumb {
    background-color: var(--c-contrast-3);
    border-radius: 8px;

    &:hover {
        background-color: var(--c-fg);
    }
}

::-moz-scrollbar-thumb {
    background-color: var(--c-contrast-3);
    border-radius: 8px;

    &:hover {
        background-color: var(--c-fg);
    }
}

.horizontal-scroll,
.vertical-scroll {
    ::-webkit-scrollbar {
        display: block !important;
    }

    ::-moz-scrollbar {
        display: block !important;
    }

    ::-webkit-scrollbar-track {
        background-color: var(--c-contrast-2);
    }

    ::-moz-scrollbar-track {
        background-color: var(--c-contrast-2);
    }

    ::-webkit-scrollbar-thumb {
        background-color: var(--c-contrast-3);
    }

    ::-moz-scrollbar-thumb {
        background-color: var(--c-contrast-3);
    }
}

.vertical-scroll {
    ::-webkit-scrollbar {
        width: 5px;
        margin-right: 10px;
    }

    ::-moz-scrollbar {
        scrollbar-width: 5px;
    }
}

.horizontal-scroll {
    ::-webkit-scrollbar {
        height: 5px;
    }

    ::-moz-scrollbar {
        height: 5px;
    }
}

//Links & Buttons

a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    pointer-events: all;
    transition: color 0.2s ease;

    &:focus {
        outline: none;
    }
}

*[role='button'],
button,
.button {
    cursor: pointer;
    pointer-events: all;
}

button,
.button {
    font: inherit;
    font-size: 11px;
    font-weight: bold;
    border: solid 1px var(--c-fg);
    border-radius: var(--b-radius);
    padding: 6px 14px;
    color: var(--c-fg);
    background-color: var(--c-bg);
    appearance: none;
    pointer-events: all;

    &[data-active='true'] {
        pointer-events: none;
    }

    & > span {
        z-index: 2;
    }

    &.btn-round {
        padding: 0;
        border-radius: 50%;
        width: 26px;
        height: 26px;
        display: inline-flex;

        &::after,
        &::before {
            border-radius: 50%;
        }

        .icon {
            margin: auto;
            --color: var(--c-fg);
            --size: 12px;
        }
    }

    &.with-icon {
        display: flex;
        align-items: center;

        .icon {
            margin-left: 10px;
        }
    }
}

//button

.animated-gradient {
    background: var(--c-blue);
}

*[data-theme='dark'] {
    .animated-gradient {
        background: var(--linear-gradient);
        background-size: 100% 100%;
        // animation: AnimateGradient 18s ease infinite;
    }
}

@keyframes AnimateGradient {
    0% {
        background-position: 0% 96%;
    }

    50% {
        background-position: 100% 5%;
    }

    100% {
        background-position: 0% 96%;
    }
}

.border-blur::before {
    --size: 0;
    content: '';
    position: absolute;
    top: var(--size);
    right: var(--size);
    bottom: var(--size);
    left: var(--size);
    background-image: var(--conic-gradient);
    filter: blur(10px);
    opacity: 0.4;
}

.border-blur-round::before {
    border-radius: 50%;
}

*[data-color='pos'],
*[data-sign='1'] {
    color: var(--c-green);
}

*[data-color='neg'],
*[data-sign='-1'] {
    color: var(--c-red);
}

*[data-theme='default'] {
    *[data-color='pos'],
    *[data-sign='1'] {
        span {
            color: var(--c-grey-60);
            padding: 2px 10px;
            background-color: var(--c-green);
            border-radius: 12px;
        }
    }

    *[data-color='neg'],
    *[data-sign='-1'] {
        span {
            color: var(--c-lightest);
            padding: 2px 8px;
            background-color: var(--c-red);
            border-radius: 12px;
        }
    }
}

w3m-modal {
    position: fixed;
    z-index: 99999;
}

@layer base {
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

@layer utilities {
    .announcement-bar {
        @apply mr-[12px] text-[14px] sm:text-[16px];
    }
    .announcement-bar b {
        @apply font-bold;
    }
}

.carousel button {
    padding: 0;
    border: none;
}

#brella-widget {
    div.brella-widget-root {
        background: none;
        border-radius: 0;
        margin: 0;
        padding: 0;
        font: inherit;

        button.brella-widget-button-primary {
            @apply px-8 py-4 border-0 font-bold text-base text-contrast-0 text-center bg-contrast-6 rounded-none w-full min-w-[200px];
        }
    }
}

input::placeholder {
    color: var(--c-text);
}

.dynamic-shadow-dom {
    width: 100%;
    //     --dynamic-base-1: var(--c-wb);
    //     --dynamic-base-2: var(--c-contrast-0);
    //     --dynamic-base-3: var(--c-contrast-1);
    //     --dynamic-base-4: var(--c-contrast-1);
    //     --dynamic-text-primary: var(--c-contrast-4);
    //     --dynamic-text-secondary: var(--c-contrast-3);
    //     --dynamic-text-tertiary: var(--c-contrast-3);
    //     --dynamic-text-size-button-secondary: 0.8rem;
    //     --dynamic-text-size-title: 1rem;
    //     --dynamic-font-family-primary: var(--f-primary);
    //     --dynamic-badge-background: var(--c-contrast-1);
    //     --dynamic-badge-color: var(--c-contrast-3);
    //     --dynamic-border-radius: 16px;
    //     --dynamic-header-background: var(--c-wb);
    //     --dynamic-search-bar-background: var(--c-contrast-0);
    //     --dynamic-search-bar-background-hover: var(--c-contrast-1);
    //     --dynamic-search-bar-background-focus: var(--c-contrast-0);
    //     --dynamic-search-bar-border: var(--c-contrast-1);
    //     --dynamic-search-bar-border-hover: var(--c-contrast-1);
    //     --dynamic-search-bar-border-focus: var(--c-contrast-2);
    //     --dynamic-footer-border-top: 1px solid var(--c-contrast-1);
    //     --dynamic-wallet-list-tile-background: var(--c-contrast-0);
    //     --dynamic-wallet-list-tile-background-hover: var(--c-contrast-1);
    //     --dynamic-text-link: var(--c-primary);
    //     --dynamic-button-primary-background: var(--c-contrast-1);
    //     --dynamic-button-primary-border: var(--c-contrast-1);
}

.operator-container {
    &::-webkit-scrollbar-track {
        border-radius: 8px;
    }
}

@keyframes slideInRight {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.slide-in-right {
    animation: slideInRight 0.3s ease-out;
}

@keyframes slideInLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.slide-in-left {
    animation: slideInLeft 0.3s ease-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fade-in {
    animation: fadeIn 0.5s ease-in-out;
}

.spinner {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-block;
    border-top: 2px solid #fff;
    border-right: 2px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.tooltip .text {
    @apply invisible opacity-0 transition-opacity duration-200 ease-in-out translate-y-[-100%] text-left;
}
  
.tooltip:hover .text {
    @apply visible opacity-100 translate-y-[calc(-100%-8px)];
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
